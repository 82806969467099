import dynamic from 'next/dynamic'
import { LoadingLine } from './../loading/Loading.component'
import { types } from '@JOTAJornalismo/jota-design-system'

/**
 * JOTA Design system
 * Button Component
 */
export const ButtonDS = dynamic<types['button']>(
    () =>
        import('@JOTAJornalismo/jota-design-system').then(
            (JOTADS) => JOTADS.Button
        ),
    { ssr: false, loading: () => <LoadingLine /> }
)

/**
 * JOTA Design system
 * Dialog Component
 */
export const DialogDS = dynamic<types['dialog']>(
    () =>
        import('@JOTAJornalismo/jota-design-system').then(
            (JOTADS) => JOTADS.Dialog
        ),
    { ssr: false, loading: () => <LoadingLine /> }
)

/**
 * JOTA Design system
 * Input Component
 */
export const InputDS = dynamic<types['input']>(
    () =>
        import('@JOTAJornalismo/jota-design-system').then(
            (JOTADS) => JOTADS.Input
        ),
    { ssr: false, loading: () => <LoadingLine /> }
)

/**
 * JOTA Design system
 * Pagination Component
 */
export const PaginationDS = dynamic<types['pagination']>(
    () =>
        import('@JOTAJornalismo/jota-design-system').then(
            (JOTADS) => JOTADS.Pagination
        ),
    { ssr: false, loading: () => <LoadingLine /> }
)

/**
 * JOTA Design system
 * Tab Component
 */
export const TabDS = dynamic<types['tab']>(
    () =>
        import('@JOTAJornalismo/jota-design-system').then(
            (JOTADS) => JOTADS.Tab
        ),
    { ssr: false }
)

/**
 * JOTA Design system
 * Accordion Component
 */
export const AccordionDS = dynamic<types['accordion']>(
    () =>
        import('@JOTAJornalismo/jota-design-system').then(
            (JOTADS) => JOTADS.Accordion
        ),
    { ssr: false }
)

/**
 * JOTA Design system
 * Tag Component
 */
export const TagDS = dynamic<types['tag']>(
    () =>
        import('@JOTAJornalismo/jota-design-system').then(
            (JOTADS) => JOTADS.Tag
        ),
    { ssr: false }
)

/**
 * JOTA Design system
 * Avatar Component
 */
export const AvatarDS = dynamic<types['avatar']>(
    () =>
        import('@JOTAJornalismo/jota-design-system').then(
            (JOTADS) => JOTADS.Avatar
        ),
    { ssr: false }
)

/**
 * JOTA Design system
 * Actions Component
 */
export const ActionDS = dynamic<types['action']>(
    () =>
        import('@JOTAJornalismo/jota-design-system').then(
            (JOTADS) => JOTADS.Action
        ),
    { ssr: false }
)

/**
 * JOTA Design system
 * Card Component
 */
export const CardDS = dynamic<types['card']>(
    () =>
        import('@JOTAJornalismo/jota-design-system').then(
            (JOTADS) => JOTADS.Card
        ),
    { ssr: false }
)

/**
 * JOTA Design system
 * Profile Card Component
 */
export const ProfileCardDS = dynamic<types['profileCard']>(
    () =>
        import('@JOTAJornalismo/jota-design-system').then(
            (JOTADS) => JOTADS.ProfileCard
        ),
    { ssr: false }
)

export enum colorsName {
    white = 'white',
    gray = 'gray',
    orange = 'orange',
    yellow = 'yellow',
    blue = 'blue',
    cyan = 'cyan',
    purple = 'purple',
    green = 'green',
    black = 'black'
}
